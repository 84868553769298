// extracted by mini-css-extract-plugin
export var addonsSection = "pricing-module--addonsSection--vAR8r";
export var cloud = "pricing-module--cloud--W7Swv";
export var currencyDropdown = "pricing-module--currencyDropdown--spYUg";
export var dropin = "pricing-module--dropin--PrlXD";
export var imagesDropin = "pricing-module--images-dropin--3fS8r";
export var logoSection = "pricing-module--logoSection--3VcRw";
export var logosContainer = "pricing-module--logosContainer--mDoR4";
export var pricingSection = "pricing-module--pricingSection--y0nVt";
export var signupBox = "pricing-module--signupBox--EWAuG";
export var signupSection = "pricing-module--signupSection--lfvBh";
export var subCopy = "pricing-module--subCopy--AapYo";
export var tiers = "pricing-module--tiers--xadke";
export var wobble = "pricing-module--wobble--br51P";