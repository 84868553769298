import React, { useState } from "react";

import { base, tooltip } from "./Tooltip.module.scss";

import { combine } from "../utils/classNames";

const Tooltip = props => {
  const { children, copy } = props;

  const [isActive, setIsActive] = useState(false);

  return (
    <span
      className={base}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      {children}
      <div className={combine(tooltip, isActive ? "--is-active" : "")}>{copy}</div>
    </span>
  );
};

export default Tooltip;
