import React from "react";

import { base, content, circle } from "./PricingContact.module.scss";

import Button from "../components/Button";

const PricingContact = props => {
  return (
    <section className={base}>
      <div className={content}>
        <h2>Transactions made simple</h2>
        <p>
          All plans are available with integrated or custom payment processing at competitive rates.
          Get the peace of mind that comes with managing all of your transactions in one place—it’s
          fast, seamless, and secure.
          <br />
          <br />
          <strong>
            *Partners with > $1M in annual media sales may qualify for a different rate. Talk to
            us today!
          </strong>
        </p>
        <div>
          <Button href="/contact">Get in touch</Button>
        </div>
      </div>
      <div className={circle}>
        <p>3.5%</p>
        <p>per transaction*</p>
      </div>
    </section>
  );
};

export default PricingContact;
