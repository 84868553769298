import React from "react";

import { base } from "./Currency.module.scss";

import { combine } from "../utils/classNames";

export const currencySymbols = {
  USD: "$",
  AUD: "$",
  GBP: "\u00A3",
};

const Currency = props => {
  const { className, currencyCode, value, hasCurrencyCode } = props;

  return (
    <span className={combine(base, className)}>
      {currencySymbols[currencyCode]}
      {value}
      {hasCurrencyCode && <small>{currencyCode}</small>}
    </span>
  );
};

export default Currency;
