import React from "react";

import { base, footer, badge, badgeContainer, small } from "./PricingTable.module.scss";

import Currency from "../components/Currency";
import Tooltip from "../components/Tooltip";
import Link from "../components/Link";
import { combine, getClassNameModifiers } from "../utils/classNames";

const Badge = props => {
  const { label } = props;

  const modifiers = getClassNameModifiers(props, ["color"]);

  return (
    <div className={badgeContainer}>
      <div className={combine(badge, modifiers)}>
        <h2>{label}</h2>
      </div>
    </div>
  );
};

const PricingTable = props => {
  const { currencyCode } = props;

  return (
    <div className={base}>
      <table>
        <thead>
          <tr>
            <th>Users</th>
            <th>
              <Badge label="Lite" color="light-blue" />
            </th>
            <th>
              <Badge label="Pro" color="teal" />
            </th>
            <th>
              <Badge label="Elite" color="blue" />
            </th>
            <th>
              <Badge label="Enterprise" color="purple" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Tooltip copy="Make the most of flexible user permissions and customisable notification settings for all stakeholders in your retail media ecosystem. Users typically include merchandise teams/buyers, partnership mangers, retail media teams, marketing teams, finance & legal individuals and operational teams (Store managers, location managers, warehouse staff, etc.).">
                Per additional user
              </Tooltip>
            </td>
            <td>
              <Currency
                value={{ USD: "POA", AUD: "POA", GBP: "POA" }[currencyCode]}
                currencyCode={currencyCode}
              />
            </td>
            <td>
              <Currency
                value={{ USD: "POA", AUD: "POA", GBP: "POA" }[currencyCode]}
                currencyCode={currencyCode}
              />
            </td>
            <td>
              <Currency
                value={{ USD: "POA", AUD: "POA", GBP: "POA" }[currencyCode]}
                currencyCode={currencyCode}
              />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th>Payment gateway options</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Tooltip copy="End-to-end billing and collections via credit card and invoice. All booking funds land directly into your nominated bank account or accounts.">
                Brandcrush payments
              </Tooltip>
            </td>
            <td>3.5%</td>
            <td>3.5%</td>
            <td>3.5%</td>
            <td>3.5%</td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="Integrate with your own finance system and maintain control over your own invoicing, collections, credit management and vendor set up process with our custom payments option.">
                Custom payments
              </Tooltip>
            </td>
            <td>0%</td>
            <td>0%</td>
            <td>0%</td>
            <td>0%</td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th>Sales</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Tooltip copy="Build and send inventory-managed proposals and track your sales funnel in real-time.">
                Proposal builder
              </Tooltip>
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="Invite unlimited brand and agency contacts into your media portal and scale your self serve bookings.">
                Contacts manager
              </Tooltip>
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="Provide select brands and agencies with custom pricing in exchange for committed media spend.">
                Commitment manager
              </Tooltip>
            </td>
            <td></td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="Manage agreements and contracts with custom terms and conditions and robust documentation management.">
                Agreement & contracts manager
              </Tooltip>
            </td>
            <td></td>
            <td></td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="Bundle your media inventory into packages that make it easier to buy and sell.">
                Media packages builder
              </Tooltip>
            </td>
            <td></td>
            <td></td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th>Marketing</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Tooltip copy="Scale self serve bookings with a customised media portal that forms an extension of your website.">
                Self-serve media portal
              </Tooltip>
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="Add additional media portals to manage leads and bookings from multiple websites with unique branding for each.">
                Multiple self-serve media portals
              </Tooltip>
            </td>
            <td></td>
            <td></td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="Link multiple media portals to a centralized explore page that makes complex media discoverable and bookable.">
                Explore page
              </Tooltip>
            </td>
            <td></td>
            <td></td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th>Operations</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Tooltip copy="End-to-end media inventory management with a real-time booking calendar for both inbound and outbound sales processes.">
                Media inventory management
              </Tooltip>
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="Message contacts and send attachments with ease.">
                In-app messaging
              </Tooltip>
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="Receive automated payouts into multiple bank accounts for partners with complex ownership structures.">
                Multiple profit centres
              </Tooltip>
            </td>
            <td></td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="Put your campaign management on autopilot with custom workflows that can be created with drag and drop functionality.">
                Custom campaign workflows
              </Tooltip>
            </td>
            <td></td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="Ensure you never miss another booking with the ability to break up reoccurring inventory into smaller portions.">
                Part-inventory mangement
              </Tooltip>
            </td>
            <td></td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
          <tr>
            <td>
              <Tooltip copy='Hide your reach and rate information and prompt all brand and agency leads to "request access".'>
                Protected listings
              </Tooltip>
            </td>
            <td></td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="Align your booking calendar and reporting with custom promotional periods.">
                Promotional periods
              </Tooltip>
            </td>
            <td></td>
            <td></td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="Transition your entire media management process from offline to online with a flexible and customizable media structure.">
                Custom media structure
              </Tooltip>
            </td>
            <td></td>
            <td></td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th>Integrations</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Tooltip copy="Take advantage of our custom payments option and push booking data into your system so that invoices or claims can be raised against brands/agencies automatically. Your finance system can also push payment status back into Brandcrush if required.">
                Finance & accounting platforms
              </Tooltip>
            </td>
            <td>Book a call</td>
            <td>Book a call</td>
            <td>Book a call</td>
            <td>Book a call</td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="Push contact details, messages and notifications into your CRM from Brandcrush.">
                Customer relationship manager (CRM)
              </Tooltip>
            </td>
            <td></td>
            <td>Book a call</td>
            <td>Book a call</td>
            <td>Book a call</td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="Create an end-to-end booking process for digital screens and digital shelf takers.">
                Content management platform (CMS)
              </Tooltip>
            </td>
            <td></td>
            <td></td>
            <td>Book a call</td>
            <td>Book a call</td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="Create a single sign-on solution for suppliers to book and manage all owned media through one centralized platform.">
                Programmatic retail media platform
              </Tooltip>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td>Book a call</td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="Automate reporting and real-time attribution for brands from in-store sales.">
                Point of sale (POS) platform
              </Tooltip>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td>Book a call</td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="Deliver tailored packages to brands that align marketing opportunities with physical ranging locations.">
                Product information management platform (PIM)
              </Tooltip>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td>Book a call</td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="Allow suppliers to manage shopper marketing and trade promotions with a single sign-on solution.">
                Enterprise resource planning platform (ERP)
              </Tooltip>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td>Book a call</td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="Allow brands to manage shopper marketing and trade promotions with a single sign-on solution.">
                Trade promotions platform
              </Tooltip>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td>Book a call</td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="Align existing business reporting process by pushing booking and pipeline data into your existing platform ecosystem.">
                Business intelligence platform (BI)
              </Tooltip>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td>Book a call</td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="Push data into your existing warehouse for flexible usage and reporting.">
                Data warehouse
              </Tooltip>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td>Book a call</td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="Streamline creative asset production and supply integrating with content creation platforms.">
                Asset design platforms
              </Tooltip>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td>Book a call</td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th>Marketplace</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Tooltip copy="Applicable on all booking request that originate from brands and agencies who discover your media via Brandcrush.com.">
                Marketplace fee
              </Tooltip>
            </td>
            <td>15%</td>
            <td>15%</td>
            <td>15%</td>
            <td>15%</td>
          </tr>
          <tr>
            <td>
              <Tooltip copy="You decide if you want some or all of your media opportunities promoted to thousands of additional brands and agencies looking for opportunities via the Brandcrush.com marketplace.">
                Marketplace listings
              </Tooltip>
            </td>
            <td className={small}>Unlimited</td>
            <td className={small}>Unlimited</td>
            <td className={small}>Unlimited</td>
            <td className={small}>Unlimited</td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th>Onboarding support</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Media asset audit</td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
          <tr>
            <td>Media pricing audit</td>
            <td></td>
            <td></td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
          <tr>
            <td>Media structure audit</td>
            <td></td>
            <td></td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
          <tr>
            <td>Operational processes audit</td>
            <td></td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th>Support</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Knowledge base</td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
          <tr>
            <td>In-app & email</td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
          <tr>
            <td>Phone</td>
            <td></td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
          <tr>
            <td>Priority support</td>
            <td></td>
            <td></td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
            <td>
              <img src="/images/checkmark-navy.svg" alt="Y" />
            </td>
          </tr>
        </tbody>
      </table>
      {/* <div className={footer}>All prices displayed are exclusive of tax</div> */}
    </div>
  );
};

export default PricingTable;
