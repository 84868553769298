import React from "react";

import { base, header, body, content, emptyContent, currency } from "./PricingTier.module.scss";

import Button from "../components/Button";
import Currency, { currencySymbols } from "../components/Currency";
import Tooltip from "../components/Tooltip";

import { combine, getClassNameModifiers } from "../utils/classNames";

const PricingTier = props => {
  const {
    title,
    copy,
    price,
    currencyCode,
    items,
    customContent,
    buttonColor,
    buttonLabel,
    href,
    target,
  } = props;

  const modifiers = getClassNameModifiers(props, ["color"]);

  return (
    <div className={base}>
      <div className={combine(header, modifiers)}>
        <h2>{title}</h2>
        <p>{copy}</p>
      </div>
      <div className={body}>
        <div className={content}>
          {customContent ? (
            <h2 className={emptyContent}>{customContent}</h2>
          ) : (
            <div>
              <h3>Starts at</h3>
              <Currency
                className={currency}
                value={price}
                currencyCode={currencyCode}
                hasCurrencyCode
              />
              <p>per month</p>
              <ul>
                {items?.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          )}
          <Button color={buttonColor} href={href} target={target ?? "_blank"}>
            {buttonLabel ?? "Get started"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PricingTier;
