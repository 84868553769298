import React, { useState } from "react";

import {
  pricingSection,
  addonsSection,
  logoSection,
  signupSection,
  currencyDropdown,
  subCopy,
  tiers,
  cloud,
  logosContainer,
  signupBox,
} from "./pricing.module.scss";

import Layout from "../layouts/Layout";
import PricingBanner from "../partials/PricingBanner";
import PricingTier from "../partials/PricingTier";
import PricingTable from "../partials/PricingTable";
import ContactSection from "../partials/ContactSection";
import LogoCarousel from "../partials/LogoCarousel";
import PricingContact from "../partials/PricingContact";
import Dropdown from "../components/Dropdown";
import Expander from "../components/Expander";
import Button from "../components/Button";
import { currencySymbols } from "../components/Currency";
import { useMediaQuery } from "../utils/hooks";

const PricingPage = () => {
  const [currencyCode, setCurrencyCode] = useState("USD");

  const isMobile = useMediaQuery("(max-width: 575px)");

  const handleDropdownClick = value => {
    setCurrencyCode(value.value);
  };

  return (
    <Layout>
      <PricingBanner />
      <section className={pricingSection}>
        <div className={currencyDropdown}>
          <span>{currencySymbols[currencyCode]} &nbsp;</span>
          <Dropdown
            values={Object.keys(currencySymbols).map(s => ({ value: s, label: s }))}
            onChange={handleDropdownClick}
          />
        </div>
        <div className={tiers}>
          <PricingTier
            title="Lite"
            copy="Perfect for any B2C business ready to monetize valuable customer touchpoints."
            currencyCode={currencyCode}
            items={[
              "2 Users",
              "Proposal builder",
              "Self-serve media portal",
              "Manage unlimited contacts",
              "Media inventory management",
              "In-app messaging",
              "Single currency media listings",
              "Onboarding support",
            ]}
            color="light-blue"
            buttonLabel="Book a call"
            price={
              {
                USD: "POA",
                AUD: "POA",
                GBP: "POA",
              }[currencyCode]
            }
            href="/book-a-demo"
            target="_self"
          />
          <PricingTier
            title="Pro"
            copy="Ideal for businesses looking to streamline media operations and scale revenue, fast."
            currencyCode={currencyCode}
            items={[
              "8 Users",
              "Proposal builder",
              "Self-serve media portal",
              "Manage unlimited contacts",
              "Media inventory management",
              "In-app messaging",
              "Multi currencies media listings",
              "Analytics reporting",
              "Custom workflows",
              "Booking calendar",
              "Commitment manager",
              "Multiple profit centres",
              "Onboarding support",
            ]}
            color="teal"
            buttonLabel="Book a call"
            price={
              {
                USD: "POA",
                AUD: "POA",
                GBP: "POA",
              }[currencyCode]
            }
            href="/book-a-demo"
            target="_self"
          />
          <PricingTier
            title="Elite"
            copy="Unlock your omnichannel media revenue potential with extremely powerful software."
            currencyCode={currencyCode}
            items={[
              "20 Users",
              "Proposal builder",
              "Multiple self-serve media portals",
              "Manage unlimited contacts",
              "Media inventory management",
              "In-app messaging",
              "Multi currencies media listings",
              "Analytics reporting",
              "Custom workflows",
              "Booking calendar",
              "Commitment manager",
              "Multiple profit centres",
              "Agreement & contracts manager",
              "Explore page",
              "Custom media structure",
              "Media packages builder",
              "Custom promotional periods",
              "Onboarding support",
            ]}
            billed="quarterly"
            color="blue"
            price={
              {
                USD: "POA",
                AUD: "POA",
                GBP: "POA",
              }[currencyCode]
            }
            buttonLabel="Book a call"
            href="/book-a-demo"
            target="_self"
          />
          <PricingTier
            title="Enterprise"
            copy="Create a fully integrated solution that will power your media revenue to new heights."
            currencyCode={currencyCode}
            customContent="Create a custom plan for your business"
            color="purple"
            buttonLabel="Book a call"
            href="/book-a-demo"
            target="_self"
          />
        </div>
        <img className={cloud} alt="" src="/images/cloud-pricing-light-blue.svg" />
        {/* <p className={subCopy}>All prices displayed are exclusive of tax</p> */}
      </section>
      <section className={addonsSection}>
        <Expander label="Add-ons & features">
          <PricingTable currencyCode={currencyCode} />
        </Expander>
      </section>
      <section className={signupSection}>
        <div className={signupBox}>
          <p>Media buyers</p>
          <Button color="navy" href="/brand/signup" isBlock={isMobile}>
            Sign up free
          </Button>
        </div>
        <div className={signupBox}>
          <p>Media owners</p>
          <Button href="/book-a-demo" isBlock={isMobile}>
            Book a call
          </Button>
        </div>
      </section>
      <section className={logoSection}>
        <h2>Trusted by the world's leading companies</h2>
        <div className={logosContainer}>
          <LogoCarousel logos={logos(isMobile)} height="3.388" />
        </div>
      </section>
      <ContactSection
        heading="Need help?"
        body="We've got you; our team offers full concierge support"
        buttonLabel="Get in touch"
        backgroundColor="grey"
        buttonColor="navy"
        foregroundImg="/images/brand-contact.svg"
        foregroundImgX={4.438}
        foregroundImgY={isMobile ? -5.625 : -3.75}
        foregroundImgWidth={isMobile ? 18.875 : 37.938}
        height={24.375}
        href="/contact"
      />
    </Layout>
  );
};

export const logos = isMobile => [
  {
    src: "/images/logos/brands/Mondelez_Grey.png",
    alt: "",
    width: isMobile ? "5" : "7.813",
    mobileOrder: 1,
  },
  {
    src: "/images/logos/brands/GeneralMills_Grey.png",
    alt: "",
    width: isMobile ? "4.5" : "5.625",
    mobileOrder: 1,
  },
  {
    src: "/images/logos/brands/KraftHeinz_Grey.png",
    alt: "",
    width: isMobile ? "6" : "8.5",
    mobileOrder: 1,
  },
  {
    src: "/images/logos/brands/Pepsi_Grey.png",
    alt: "",
    width: isMobile ? "6.5" : "10",
    mobileOrder: 1,
  },
  {
    src: "/images/logos/brands/Coles_Grey.png",
    alt: "",
    width: isMobile ? "4.5" : "5.625",
    mobileOrder: 1,
  },
  {
    src: "/images/logos/brands/Asahi_Grey.png",
    alt: "",
    width: isMobile ? "4" : "5.625",
    mobileOrder: 1,
  },
  {
    src: "/images/logos/brands/Nestle_Grey.png",
    alt: "",
    width: isMobile ? "5.5" : "7.5",
    mobileOrder: 1,
  },
  {
    src: "/images/logos/brands/Mars_Grey.png",
    alt: "",
    width: isMobile ? "4.5" : "5.625",
    mobileOrder: 1,
  },
];

export default PricingPage;
