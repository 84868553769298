import React, { useState } from "react";

import { base, header, main } from "./Expander.module.scss";

import { combine } from "../utils/classNames";

const Expander = props => {
  const { className, label, children } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={combine(base, className)}>
      <div
        className={combine(header, isExpanded ? "--is-expanded" : "")}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <p>{label}</p>
        <p>{isExpanded ? "-" : "+"}</p>
      </div>
      <div className={combine(main, isExpanded ? "--is-expanded" : "")}>{children}</div>
    </div>
  );
};

export default Expander;
