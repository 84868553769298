import React, { useState } from "react";

import { base, sheet, label } from "./Dropdown.module.scss";

import { useClickOutside } from "../utils/hooks";
import { combine } from "../utils/classNames";

const Dropdown = props => {
  const { values, onChange, className } = props;

  const [activeValue, setActiveValue] = useState(values[0]);
  const [isVisible, setIsVisible] = useState(false);

  useClickOutside(() => {
    setIsVisible(false)
  }, isVisible);

  const handleClick = () => {
    setIsVisible(true);
  };

  const handleItemClick = value => {
    setActiveValue(value);

    if (onChange && typeof onChange === "function") {
      onChange(value);
    }
  };

  return (
    <div className={combine(base, className, isVisible ? "--is-visible" : "")}>
      <div className={label} onClick={handleClick}>
        {activeValue.label}
        <i className="material-icons">expand_more</i>
      </div>
      {isVisible && (
        <ul className={sheet}>
          {values.map(value => (
            <li key={value.value} onClick={() => handleItemClick(value)}>
              {value.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
